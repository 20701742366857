.tour-tip {
  display: none;
  position: absolute;
  background: #1C252E;
  color: #FFF;
  z-index: 101;
  top: 0;
  left: 2.5%;
  font-family: inherit;
  font-weight: 400;
  max-width: 530px;
}
.tour-tip h3 {
  font-size: 1.3em;
  margin-bottom: 0.8rem;
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
}

.tour-tip p {
  color: #f1f1f1;
  font-size: .86em;
  line-height: 1.5em;
  margin: 0 0 1em;
  text-align: justify;
}

.tour-tip button,
.tour-tip .button {
  background-color: #1564C0;
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.5) inset;
  transition: background-color 300ms ease-out;
  cursor: pointer;
  font-family: inherit;
  line-height: 1;
  position: relative;
  text-decoration: none;
  text-align: center;
  display: inline-block;
  font-size: .8em;
  color: #FFF;
  border-style: solid;
  border-width: 0;
  margin: 0 0 1.25em;
  padding: .5em 1.25em .6em;
}

.tour-tip button small,
.tour-tip .button small {
  padding-top: .625em;
  padding-bottom: .5625em;
  -webkit-appearance: none;
}

.tour-tip button:hover,
.tour-tip .button:hover {
  background-color: #105299;
}

.tour-tip .tour-arrow {
  display: block;
  position: absolute;
  left: 22px;
  width: 0;
  height: 0;
  border: inset 14px;
}

.tour-tip .tour-arrow.tt-top {
  border-style: solid;
  bottom: -27px;
  border-color: #1C252E transparent transparent !important;
}

.tour-tip .tour-arrow.tt-bottom {
  border-style: solid;
  top: -28px;
  border-color: transparent transparent #1C252E !important;
}

.tour-tip .tour-arrow.tt-right {
  border-style: solid;
  left: -27px;
  top: 28px;
  border-color: transparent #1C252E transparent transparent !important;
}

.tour-tip .tour-arrow.tt-left {
  border-style: solid;
  left: 100%;
  top: 28px;
  border-color: transparent transparent transparent #1c252e !important;
}

.tour-tip .tour-content-wrapper {
  padding: 1.125em 1.25em 1.5em;
}

.tour-tip .tour-content-wrapper .button {
  margin-bottom: 0 !important;
}

.tour-tip .tour-close-tip {
  position: absolute;
  right: 12px;
  top: 10px;
  color: #fff !important;
  text-decoration: none;
  font-size: 30px;
  font-weight: 400;
  line-height: 0.5 !important;
  cursor: pointer;
}

.tour-tip .tour-close-tip:hover,
.tour-tip .tour-close-tip:focus {
  color: #eee !important;
}

.tour-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 100;
}

.tour-element-active {
  z-index: 120 !important;
  position: relative;
  background: #fff;
}